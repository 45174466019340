@import './colors.scss';

@import url('https://fonts.googleapis.com/css?family=Open+Sans:400');

$open-sans: 'Open Sans', sans-serif;

body {
  font-family: $open-sans;
}

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0;
}

fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

textarea {
  resize: vertical;
}

h1 {
  font-size:12vw;
  line-height:1.1;
  margin:0 10% 0 6%;
  color: $black;
  font-weight:bold;
  font-family: $open-sans;
}

h2 {
  font-size:3rem;
  line-height:1.5;
  margin:0 10% 30px 7%;
  font-family: $open-sans;
}

p {
  font-size:1.5rem;
  line-height:1.5;
  margin:0 10% 30px 7%;
  font-family: $open-sans;
}

li {
  font-size:28px;
  line-height:1.5;
  margin-left:4%;
  list-style-type: none;
}

span {
  color: $yellow;
}

i {
  color: $yellow;
}

a {
  text-decoration: none;
  color:inherit;
}
