body {
  margin: 0;
  padding: 0;
}
.App {
  text-align: center;
  position: relative;
}

section {
  padding: 0;
  max-width: 600px;
  margin: 0 auto;
}
.page {
  position: absolute;
  left: 0;
  right: 0;
}

.fade-appear,
.fade-enter {
  opacity: 0;
  z-index: 1;
}
.fade-appear-active,
.fade-enter.fade-enter-active {
  opacity: 0;
  transition: opacity 300ms linear 150ms;
}

.fade-exit {
  opacity: 1;
}

.fade-exit.fade-exit-active {
  opacity: 0;
  transition: opacity 150ms linear;
}

/* advanced - enter transition 800ms, exit 150ms
.fade-appear,
.fade-enter {
    opacity: 0;
    z-index: 1;
    transform: translateY(-10px);
}
.fade-appear p,
.fade-enter p {
  transform: translateY(-5px);
  opacity: 0;
}
.fade-appear-active,
.fade-enter.fade-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 300ms linear 150ms, transform 300ms ease-out 150ms;
}
.fade-appear-active p,
.fade-enter.fade-enter-active p {
  transform: translateY(0);
  opacity: 1;
}
.fade-appear-active p:nth-child(2),
.fade-enter.fade-enter-active p:nth-child(2) {
  transition: opacity 300ms linear 300ms, transform 300ms ease-out 300ms;
}
.fade-appear-active p:nth-child(3),
.fade-enter.fade-enter-active p:nth-child(3) {
  transition: opacity 300ms linear 400ms, transform 300ms ease-out 400ms;
}
.fade-appear-active p:nth-child(4),
.fade-enter.fade-enter-active p:nth-child(4) {
  transition: opacity 300ms linear 500ms, transform 300ms ease-out 500ms;
}
.fade-exit {
    opacity: 1;
    transform: translateY(0);
}
.fade-exit.fade-exit-active {
    opacity: 0;
    transform: translateY(-10px);
    transition: opacity 150ms linear, transform 150ms ease-in;
}

*/

.slide-enter {
  opacity: 0;
  transform: scale(0.97) translateY(5px);
  z-index: 1;
}
.slide-enter.slide-enter-active {
  opacity: 1;
  transform: scale(1) translateY(0);
  transition: opacity 3000ms linear 1000ms, transform 3000ms ease-in-out 1000ms;
}

/* slide exit */
.slide-exit {
  opacity: 1;
  transform: scale(1) translateY(0);
}
.slide-exit.slide-exit-active {
  opacity: 0;
  transform: scale(0.97) translateY(5px);
  transition: opacity 1500ms linear, transform 1500ms ease-out;
}
.slide-exit-done {
  opacity: 0;
}