.islandGrid {
  grid-area: islandGrid;
  justify-self: center;
}

@media all and (high-contrast:none) {
  .islandGrid {
    grid-row: 3;
    grid-row-span: 1;
    grid-column: 4;
    grid-column-span: 1;
  }
}

.islandsWrapper {
  height:0;
}

svg#Capa1 {
  position: relative;
  top: -208px;
  z-index: 1;
}

/* refactor */
@media only screen and (max-width: 920px) {
  svg#Capa1 {
    width: 260px;
    top: -87px;
  }
}