.sunContainer {
  overflow: hidden;
}

.sunGrid {
  grid-area: sunGrid;
  justify-self: end;
  height:140px;
}

@media all and (high-contrast:none) {
  .sunGrid {
    grid-row: 1;
    grid-row-span: 1;
    grid-column: 4;
  }
}

@media (max-width: 1400px) {
  .sunGrid {
    height:220px;
  }
}

.sun {
  width: 200px;
  height: 200px;
  background: #FDB813;
  border-radius: 50%;
  display: inline-block;
  margin-right: 20px;
  position: relative;
  top: -70px;
  margin-right: 0;
  right:-43px;
  overflow: hidden;
  -webkit-box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  -moz-animation: bounce 2s infinite;
  -webkit-animation: bounce 2s infinite;
  animation: bounce 2s infinite;
}

.sun1 {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 50px 20px 0 20px;
  border-color: #FDB813 transparent transparent transparent;
  top: 135px;
  right: 11px;
  position: absolute;
  transform: rotate(-10deg);
}

.sun2 {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 50px 20px 0 20px;
  border-color: #FDB813 transparent transparent transparent;
  top: 130px;
  right: 87px;
  position: absolute;
  transform: rotate(21deg);
}

.sun3 {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 50px 20px 0 20px;
  border-color: #FDB813 transparent transparent transparent;
  top: 1px;
  right: 174px;
  position: absolute;
  transform: rotate(94deg);
}

.sun4 {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 50px 20px 0 20px;
  border-color: #FDB813 transparent transparent transparent;
  top: 85px;
  right: 150px;
  position: absolute;
  transform: rotate(53deg);
}

@keyframes bounce {
  0%, 50%, 100% {
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  25% {
    -moz-transform: translateY(-5px);
    -ms-transform: translateY(-5px);
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px);
  }
  75% {
    -moz-transform: translateY(-3px);
    -ms-transform: translateY(-3px);
    -webkit-transform: translateY(-3px);
    transform: translateY(-3px);
  }
}