/* Fish animations */

svg#fish {
  top: 5em;
}

svg.fish{
  overflow:visible;
}

@-webkit-keyframes swim
{
  0% {left: 0;}
  50% {left: 50%;}
  100% {left: 100%; opacity:0.4;}
}

@keyframes swim
{
  0% {left: 0;}
  50% {left: 50%;}
  100% {left: 100%; opacity: 0.4;}
}

.fish{
  width: 235px;
  height: 104px;
  margin-left: -235px;
  position: absolute;
  animation: swim 40s; //40
  -webkit-animation: swim 40s;
  animation-iteration-count: infinite;
  -webkit-animation-iteration-count: infinite;
  animation-timing-function: linear;
  -webkit-animation-timing-function: linear;
  margin-top: 90px;
}

svg #fish1,
svg #fish2,
svg #fish3,
svg #fish4,
svg #fish5,
svg #fish6 {

  -moz-animation: bounce 2s infinite;
  -webkit-animation: bounce 2s infinite;
  animation: bounce 2s infinite;

}

svg #fish2{
  animation-delay: 0.5s;
  -webkit-animation-delay: 0.5s;
}

svg #fish3{
  animation-delay: 0.2s;
  -webkit-animation-delay: 0.2s;
}

svg #fish4{
  animation-delay: 0.4s;
  -webkit-animation-delay: 0.4s;
}

svg #fish5{
  animation-delay: 0.1s;
  -webkit-animation-delay: 0.1s;
}

svg #fish6{
  animation-delay: 0.3s;
  -webkit-animation-delay: 0.3s;
}

/**/
@-moz-keyframes bounce {
  0%, 50%, 100% {
    -moz-transform: translateY(0);
    transform: translateY(0);
  }
  25% {
    -moz-transform: translateY(-5px);
    transform: translateY(-5px);
  }
  75% {
    -moz-transform: translateY(-3px);
    transform: translateY(-3px);
  }
}
@-webkit-keyframes bounce {
  0%, 50%, 100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  25% {
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px);
  }
  75% {
    -webkit-transform: translateY(-3px);
    transform: translateY(-3px);
  }
}
@keyframes bounce {
  0%, 50%, 100% {
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  25% {
    -moz-transform: translateY(-5px);
    -ms-transform: translateY(-5px);
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px);
  }
  75% {
    -moz-transform: translateY(-3px);
    -ms-transform: translateY(-3px);
    -webkit-transform: translateY(-3px);
    transform: translateY(-3px);
  }
}