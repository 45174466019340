@import './colors.scss';

.mainGrid {
  grid-area: mainGrid;
}

.mainWrapper {
  max-width: 1000px;
  margin: 0 auto;
  position: relative;
  top:60px;
}

.container {
  margin: 0 auto;
  padding-left: 1.5rem;
  padding-right: 1.5rem;

  h1 {
    font-size: 2.8rem;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    margin-left:7%;
  }

  h2 {
    font-size: 1.8rem;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }

  h3 {
    font-size: 1.5rem;
    margin-top: 1.5rem;
    margin-bottom: 0.8rem;
    margin-left:7%;
  }

  span {
    font-size: 1em;
    display: block;
    color: #444;
    font-weight: 400;
    margin-left:7%;
    line-height: 1.4;
  }

  time {
    font-size: 0.75rem;
    color: #444;
    margin-left:7%;
  }

  p {
    font-size:1rem;
    line-height: 1.7;
    margin-top:1rem;
  }

  hr {
    width:30%;
    margin-left:7%;
  }

  .linkarrow {
    color: $yellow;
  }
}

.exit {
  position:fixed;
  bottom:2.25rem;
  right:2.25rem;
  font-size: 3rem;
}

.button {
  font-size: 3rem;

  ul {
    padding-inline-start: 0px;
    cursor: pointer;
  }
}

.tentacle {
  width: 100%;
  height:80vh;
  background-image: url("../images/tentacle.svg");
  shape-rendering: optimizeSpeed;
  position: fixed;
  bottom: -510px;
  z-index: -10;
  left: -510px;
}



