
.modal {
  padding-top:60px;
  max-width: 980px;
  margin: 0 auto;

  h2 {
    font-size:6rem;
  }

  @media (max-width: 1000px) {
    h2 {
      font-size:11vw !important;
      margin-top: 50px;
    }
  }
}

.exit {
  position:fixed;
  bottom:2.25rem;
  right:2.25rem;
  font-size: 3rem;
}

.button {
  font-size: 3rem;

  ul {
    padding-inline-start: 0px;
    cursor: pointer;
  }
}

.monkey {
  position: absolute;
  top: 0;
  right: 7%;
}

input[type="text"] {
  display:block;
  width: 200px;
  border-radius: 5px;
  border: solid 3px dimgrey;
}

textarea {
  display:block;
  width: 100%;
  height: 200px;
  border-radius: 5px;
  border: solid 3px dimgrey;
}

$fuschia: purple;
$button-bg: $fuschia;
$button-text-color: #fff;
$baby-blue: #f8faff;

.bubblyButton {
  font-family: 'Helvetica', 'Arial', sans-serif;
  display: inline-block;
  font-size: 1em;
  padding: 1em 2em;
  margin-bottom: 60px;
  -webkit-appearance: none;
  appearance: none;
  background-color: $button-bg;
  color: $button-text-color;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  position: relative;
  transition: transform ease-in 0.1s, box-shadow ease-in 0.25s;
  box-shadow: 0 2px 25px purple;
}




